
import React, { useState } from 'react';
import { Modal, Form, Input, Button} from 'antd';
import Users from '../../logic/users';
import { notification } from 'antd';

const ResetPinModal = ({ userId, msisdn }) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [ fetching, setFetching ] = useState(false);

    const toggleModal = () => {
        setVisible(!visible);
    };

    

    // const resetPin = async () => {
    //     setFetching(true);
    //     form.validateFields().then(async values => {
    //         // wait to reset the user pin
    //         const user = new Users({
    //             user_id: userId,
    //             new_pin: values.pin,
    //         });
    //         await user.resetPin();
    //         form.resetFields();
    //         setVisible(false);
    //     });
    //     setFetching(false);
    // }

    const resetPin = async () => {
        setFetching(true);
    
        try {
            // Ensure userId is accessible here
            if (!userId) {
                throw new Error('User ID is undefined. Please contact system administrator and Check the source');
            }
    
            await form.validateFields().then(async (values) => {
                // Reset the user pin
                const user = new Users({
                    user_id: userId,
                    new_pin: values.pin,
                });
    
                await user.resetPin();
    
                // Reset the form and hide the modal
                form.resetFields();
                setVisible(false);
            });
        } catch (error) {
            console.error('Error resetting the pin:', error.message);
            // Show error notification
            notification.error({
                message: 'Reset Pin Failed',
                description: error.message || 'An unexpected error occurred.',
                duration: 5, // Optional: How long to show the notification
            });
        } finally {
            setFetching(false);
        }
    };
    

    return (
        <>
            <Button onClick={toggleModal}>Reset Pin</Button>
            <Modal
                open={visible}
                title={`Reset Pin for User ${msisdn}`}
                onCancel={()=>{form.resetFields(); setVisible(false)}}
                onOk={resetPin}
                okText="Reset Pin"
                okButtonProps={{loading: fetching, disabled: fetching}}
                cancelButtonProps={{disabled: fetching}}
            >
                <Form form={form} layout="vertical" onSubmit={resetPin}>
                    <Form.Item
                        name="pin"
                        label="New Pin"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the new pin',
                            },
                        ]}
                    >
                        <Input.Password placeholder='Please input the new pin' maxLength={5}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ResetPinModal;
