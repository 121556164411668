// const apiDomain = "https://disan-admin-api2-zsmyw3mm3q-od.a.run.app";
// const apiDomain = "http://localhost:8000";
// const apiDomain = "https://admin-api.disan.celesinternational.com"
const apiDomain = "https://api.disan.app"

const callApi = async (data) => {
    try{
        const URL = `${apiDomain}${data.endpoint}`;
    let headers = { "Content-type": data.contentType }
    
    if (data.requireAuth){ 
        const token = await sessionStorage.getItem('token');
        const TOKEN  = `Bearer ${token}`;
        headers['Authorization'] = TOKEN;
    }

    let requestConsiderations = {
        method: data.method,
        body: data.body,
        headers: headers,
    }

    // to remove the body
    if (data.method === 'GET'){
        requestConsiderations = {
            method: data.method,
            headers: headers,
        }
    }

    if(data.contentType === 'ignore'){delete headers['Content-Type']}

    const ApiResponse = fetch( URL, requestConsiderations )
    .then( async response => {
        if (response.status === 401 && data.endpoint !== '/api/v1/auth/login/'){
            window.location.href="/login/";
        }
        return await response.json(); 
    })
    .catch(e => {console.log(e)})
    return ApiResponse;
    } catch(error){
        console.log(error);
        return false;
    }

}


export { callApi }